import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';

import { Page, Snippet } from 'src/components';
import paths from 'src/constants/paths';
import { Segment, SegmentEvent } from 'src/lib';
import { BenefitsAdvisorContext } from '../types';

const SGrid = styled.div`
  display: grid;
  grid-template-columns: 350px auto 300px;
  margin-bottom: 100px;
  max-width: 100vw;
  grid-gap: var(--side-margin);
  @media (max-width: 1400px) {
    grid-template-columns: 300px auto 64px;
  }
  @media (max-width: 1200px) {
    grid-template-columns: 300px auto 0;
    > div:last-child {
      display: none;
    }
  }
  @media (max-width: 758px) {
    grid-template-columns: 0 auto 0;
    grid-gap: 0;
    .no-flex-mob {
      display: block;
    }
  }
`;

const SToolbar = styled.div`
  height: var(--s-nav-height);
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 0;
  background: var(--c-bg-0);
  font-weight: 400;
  font-size: var(--fs-b2);
  color: var(--c-fg-1);
  .o100 {
    color: var(--c-fg-0);
  }
  border-bottom: 1px solid var(--c-border-light);
  position: sticky;
  z-index: 99999;
  top: 0;
  &.transparent {
    background: none;
    font-weight: 400;
  }
`;

const SColor = styled.div`
  border-radius: 0 24px 24px 0;
  background: var(--g-${(p) => p.color}-light);
  position: sticky;
  height: calc(var(--s-nav-height) + 100vh);
  top: 0;
  width: 100%;
  max-width: 100%;
  left: 0;
  padding: var(--s-nav-height) 0 0;
  overflow: scroll;
  box-sizing: border-box;
  top: calc(var(--s-nav-height) * -1);
  .o50:hover {
    opacity: 1;
  }
  .body {
    padding: var(--side-margin);
  }
  .tb2 {
    display: none;
  }
  @media (max-width: 758px) {
    backdrop-filter: blur(100px);
    position: absolute;
    padding: 0;
    top: calc(var(--s-nav-height) - 4px);
    height: ${(p) => (p.expanded ? '80vh' : 'calc(1*var(--s-nav-height))')};
    transition: height 0.25s cubic-bezier(0.075, 0.82, 0.165, 1);
    z-index: 99999;
    border-radius: 16px;
    margin: 0 var(--side-margin);
    width: calc(100% - 2 * var(--side-margin));
    border: none;
    .body {
      display: ${(p) => (p.expanded ? 'block' : 'none')};
      position: absolute;
      top: calc(1 * var(--s-nav-height));
      bottom: 0px;
      width: 100%;
      overflow: scroll;
    }
    .tb2 {
      display: flex;
      padding: 0 var(--side-margin);
      border-color: ${(p) => (p.expanded ? 'var(--c-border-light)' : 'transparent')};
      height: calc(1 * var(--s-nav-height));
      cursor: pointer;
      &:hover {
        background: var(--c-border-light);
      }
      img {
        transition: transform 0.25s cubic-bezier(0.075, 0.82, 0.165, 1);
        transform: rotateZ(${(p) => (p.expanded ? '90' : '0')}deg);
      }
    }
    h3 {
      display: none;
    }
  }
`;

const SLinks = styled.div`
  padding: 0 0 24px;
  padding-right: 0;
`;

const SLink = styled.div`
  &.h {
    a,
    div {
      font-weight: 600 !important;
    }
  }
  a,
  div {
    font-size: calc(var(--fs-b2) - 1px);
    padding: 8px 0 8px 32px;
    font-weight: 400;
    color: var(--c-fg-1);
    height: 32px;
    display: flex;
    align-items: center;
    line-height: 1;
    margin-left: -4px;
    width: 100%;

    &:hover {
      color: var(--c-fg-0);
      .bullet {
        background: var(--c-fg-1);
      }
    }

    &.active {
      font-weight: 500;
      color: var(--c-fg-0);
      transition: none;
      letter-spacing: -0.1px;
      .bullet {
        background: var(--c-fg-0);
      }
    }
  }
  &.h > div,
  &.h > a {
    background: url(${(p) => p.icon}) left/24px no-repeat;
    font-weight: 400;
    color: black;
    filter: var(--filter);
  }
`;

const SPost = styled.div`
  margin: var(--s-nav-height) auto;
  .body {
    padding: var(--side-margin);
    width: var(--width-read);
    @media (max-width: 1400px) {
      width: 100%;
    }
    @media (max-width: 800px) {
      margin-top: calc(var(--s-nav-height) * 2);
    }
  }
`;

/**
 *
 */
const GuidePostTemplate = ({ pageContext }) => {
  const [expanded, setExpanded] = useState(false);
  const { post, personas = [], verticals = [], chapter, guide, index } = pageContext;

  const prevPost = chapter?.snippets?.[index - 1];
  const nextPost = chapter?.snippets?.[index + 1];

  useEffect(() => {
    document.addEventListener(
      'click',
      function (e) {
        if (e?.target?.search?.includes('benefits_advisor')) {
          Segment.track(SegmentEvent.TALK_TO_BENEFITS_ADVISOR_CLICKED, {
            context: BenefitsAdvisorContext.guide,
          });
        }
      },
      false,
    );
  }, []);

  return (
    <Page
      type="article"
      prefooter={<></>}
      transparentNav
      navBorder={false}
      hideLinks
      hideBanner
      unfixNav
      metadata={{
        pageType: 'GuidePost',
        title: post?.title,
        description: post?.tldr || post?.subtitle,
        datePublished: post?.createdAt,
        dateModified: post?.updatedAt,
        image: `/img/og/guides/${guide?.slug}.png`,
        canonical: `${paths.GUIDES}/${guide?.slug}/${post?.slug}`,
        faqs: post?.faqs,
        data: [
          { label: 'Guide', value: guide?.title },
          { label: 'Chapter', value: chapter?.title },
        ],
        keywords: [
          ...personas,
          ...verticals,
          ...(guide?.meta?.keywords || []),
          ...(post?.terms?.map((term) => term.name) || []),
          ...(post?.terms?.map((term) => term.full) || []),
          ...(post?.tagset?.topics || []),
          ...(guide?.meta?.keywords || []),
        ],
      }}
    >
      <SGrid>
        <div>
          <SColor color={guide?.color} expanded={expanded}>
            <SToolbar
              className="tb2 transparent"
              onClick={() => {
                setExpanded(!expanded);
              }}
            >
              <span className="o100 m">{guide?.title}</span>
              <img
                loading="lazy"
                src="/iconic/chevron-right.svg"
                width={24}
                height={24}
                style={{ filter: 'var(--filter' }}
              />
            </SToolbar>
            <div className="body">
              {guide?.chapters
                ?.filter((c) => c.snippets?.length > 0)
                ?.map((chap) => (
                  <div key={chap.id}>
                    <SLink className="h" icon={chap.icon?.url}>
                      <div to={`${paths.GUIDES}/${guide?.slug}/`}>{chap.title}</div>
                    </SLink>
                    <SLinks>
                      {chap.snippets?.map((snip) => (
                        <SLink key={snip?.id}>
                          <Link
                            activeClassName="active"
                            to={`${paths.GUIDES}/${guide?.slug}/${snip?.slug}`}
                          >
                            {snip.shortTitle || snip.title}
                          </Link>
                        </SLink>
                      ))}
                    </SLinks>
                  </div>
                ))}
            </div>
          </SColor>
        </div>
        <div>
          <SPost>
            <Snippet
              post={post}
              prevPost={prevPost}
              nextPost={nextPost}
              guide={guide}
              chapterTitle={chapter?.title}
            />
          </SPost>
        </div>
        <div></div>
      </SGrid>
    </Page>
  );
};

export default GuidePostTemplate;
